.contact-item{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    min-height: 400px;
    border-radius: 10px;
    background-color: #111;
    color: #FFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.contact-item:nth-child(even){
    background-color: #E5DCBC;
    color: #111;
}
.contact-item h1{
    font-family: 'Montserrat';
    font-size: 28px;
    text-align: center;
}
.contact-item ul{
    text-align: center;
    list-style: none;
    padding: 20px;
    font-family: 'Montserrat';
    font-size: 20px;
}

@media screen and (max-width: 1300px) {
    .contact-item{
        min-height: 300px;
    }
    .contact-item h1{
        font-size: 22px;
    }
    .contact-item ul{
        font-size: 16px;
    }
}
@media screen and (max-width: 1000px) {
    .contact-item{
        width: 90%;
        margin: auto;
        max-width: 650px;
    }
}

@media screen and (max-width: 660px) {
    .contact-item{
        min-height: 300px;
    }
    .contact-item h1{
        font-size: 22px;
    }
    .contact-item ul{
        font-size: 14px;
    }
}