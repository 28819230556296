.contact-section-wrapper{
    margin: 0px auto;
    width: 90%;
    max-width: 1400px;
    height: min-content;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    margin-bottom: 200px;
}
@media screen and (max-width: 1000px) {
    .contact-section-wrapper{
        grid-template-columns: repeat(1,1fr);
    }
}