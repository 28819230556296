.about-item{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    margin: auto;
    height: 100%;
}
.about-item-icon{
    font-size: 50px;
    color: #444;
}
.about-item h6{
    font-size: 32px;
    padding: 20px;
    font-family: 'Playfair Display';
    color: #333;
}
.about-item p{
    font-family: 'Montserrat';
    width: 90%;
    max-width: 1300px;
    text-align: center;
    font-size: 16px;
}
@media screen and (max-width: 1300px) {
    .about-item-icon{
        font-size: 40px;
    }
    .about-item h6{
        font-size: 26px;
    }
    .about-item p{
        font-size: 14px;
    }
}
@media screen and (max-width: 630px) {
    .about-item{
        width: 100%;
    }
}