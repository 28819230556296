.gallery-item{
    width: 100%;
    margin: auto;
    background-color: #DDD;
    height: 260px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    overflow: hidden;
}
.gallery-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

@media screen and (max-width:660px) {
    .gallery-item{
        width: 100%;
        margin: auto;
        background-color: #DDD;
        height: 180px;
    }
}