.categories-wrapper{
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    font-family: 'Montserrat';
    margin-top: 40px;
}
@media screen and (max-width: 1300px) {
    .categories-wrapper{
        height: 400px;
    }
}
@media screen and (max-width: 960px) {
    .categories-wrapper{
        height: auto;
        flex-direction: column;
    }
}