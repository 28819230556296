.nav-bar{
    width: 60%;
    max-width: 750px;
    height: 100%;
    display: flex;
    align-items: center;
}
.nav-links{
    display: grid;
    list-style: none;
    gap: 15px;
    width: 100%;
    grid-template-columns: repeat(4,1fr);
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 960px) {
    .nav-bar{
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
        max-width: none;
        height: 100vh;
        display: flex;
        align-items: flex-start;
        background-color: rgb(241, 231, 217)
    }
    .nav-links{
        margin-top: 50px;
        display: grid;
        list-style: none;
        gap: 15px;
        width: 100%;
        grid-template-columns: repeat(1,1fr);
        height: 400px;
    }
}