.page-hero-section{
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-family: 'Prata';
    color: #222;
}
@media screen and (max-width:660px) {
    .page-hero-section{
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-family: 'Prata';
        color: #222;
    }
}