.popular-services-wrapper{
    margin-top: 40px;
    width: 100%;
    background-color: #212121;
    height: min-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    gap: 10px;
}
.popular-services-wrapper h1{
    font-family: 'Prata';
    color: #EDEDED;
    font-weight: 400;
    letter-spacing: 0.5px;
}
.popular-services-inner{
    width: 90%;
    max-width: 1400px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;
}
@media screen and (max-width: 1300px) {
    .popular-services-wrapper h1{
        font-size: 26px;
    }
}
@media screen and (max-width: 960px) {
    .popular-services-inner{
        grid-template-columns: repeat(1,1fr);
        max-width: 600px;
    }
}
@media screen and (max-width: 630px) {
    .popular-services-wrapper h1{
        font-size: 22px;
    }
    .popular-services-inner{
        width: 100%;
    }
}