.about-section-wrapper{
    margin-top: 50px;
    width: 100%;
    height: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 20px;
    flex-direction: column;
}
.about-section-inner{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    width: 90%;
    max-width: 1400px;
}

@media screen and (max-width: 960px) {
    .about-section-inner{
        grid-template-columns: repeat(1,1fr);
        max-width: 600px;
    }
}