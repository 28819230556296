.image-item{
    box-sizing: border-box;
    display: block;
    object-fit: cover;
    border-radius: 5px;
    height: 240px;
}
@media screen and (max-width: 630px) {
    .image-item{
        height: 180px;
    }
}