.nav-link{
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.nav-link .link{
    color: #222;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.2s ease;
    box-sizing: border-box;
    border-bottom: 5px solid #FBFAF3;
    border-top:5px solid #FBFAF3;
}
.nav-link .link:hover{
    border-bottom: 5px solid #333;
}
@media screen and (max-width: 960px){
    .nav-link .link{
        border-bottom: 0px;
        border-top:0px
    }
    .nav-link .link:hover{
        border-bottom: none;
    }
}