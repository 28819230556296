.category-container{
    width: 100%;
    background-color: #FFF;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    transition: 0.2s;
    cursor: pointer;
    height: 400px;
    min-height: 320px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.category-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.2s;
}
.category-container:hover img{
    transform: scale(1.03);
}
.category-title{
    width: 100%;
    height: 75px;
    background-color: #ffffffd2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    font-size: 24px;
    color: #222;
}
.category-link{
    margin: 0;
    padding: 0;
    width: 40%;
    max-width: 710px;
}

@media screen and (max-width: 1300px) {
    .category-container{
        height: 300px;
    }
    .category-title{
        font-size: 22px;
    }
}
@media screen and (max-width: 960px) {
    .category-link{
        margin: 0;
        padding: 0;
        width: 90%;
        max-width: 550px;
    }
}
@media screen and (max-width: 630px) {
    .category-link{
        width: 80%;
    }
    .category-container{
        height: 240px;
        min-height: 0;
    }
    .category-title{
        height: 60px;
        font-size: 16px;
    }
}