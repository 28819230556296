.image-slider-inner{
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    left: 0;
    animation: sliderAnimation 40s infinite;
}
.slider-item{
    width: 100%;
    display: block;
    flex-shrink: 0;
    height: 100%;
}
img{
    object-fit: cover;
    width: 100%;
}
@keyframes sliderAnimation {
    0% { left: 0%; }
    8.33% { left: 0%; }
    16.66% { left: -100%; }
    25% { left: -100%; }
    33.33% { left: -200%; }
    41.66% { left: -200%; }
    50% { left: -300%; }
    58.33% { left: -300%; }
    66.66% { left: -200%; }
    75% { left: -200%; }
    83.33% { left: -100%; }
    91.66% { left: -100%; }
    100% { left: 0%; }
}
@media screen and (max-width: 680px){
    .image-slider-inner{
        position: static;
        height: 100%;
    }
}