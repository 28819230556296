.reservation-section-wrapper{
    width: 90%;
    max-width: 1400px;
    margin: auto;
    height: 500px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 50px;
    margin-bottom: 200px;
}
.reservation-section-wrapper .reservation-section-banner{
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 10px;
}
.reservation-section-wrapper .reservation-description-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-family: 'Montserrat';
    background-color: #FFF;
    border-radius: 10px;
}
.reservation-section-wrapper .reservation-description-container p{
    width: 350px;
    text-align: center;
}
.reservation-section-wrapper .reservation-description-container button{
    padding: 15px 55px;
    font-size: 20px;
    border-radius: 40px;
    background-color: #E5DCBC;
    border: 0;
    cursor: pointer;
    color: #222;
}
.salon-expert-logo{
    width: 140px;
}
@media screen and (max-width: 1040px) {
    .reservation-section-wrapper{
        grid-template-columns: repeat(1,1fr);
        height: auto;
    }
    .reservation-section-wrapper .reservation-section-banner{
        width: 100%;
        max-width: 660px;
        height: 280px;
        margin: auto;
        box-sizing: border-box;
        min-width: 0px;
    }
    .reservation-section-wrapper .reservation-description-container{
        width: 100%;
        max-width: 660px;
        height: auto;
        padding: 20px 10px;
        box-sizing: border-box;
        margin: auto;
    }
    .reservation-section-wrapper .reservation-description-container img{
        width: 80px;
    }
    .reservation-section-wrapper .reservation-description-container h1{
        font-size: 22px;
    }
    .reservation-section-wrapper .reservation-description-container p{
        font-size: 14px;
        width: 100%;
    }
    .reservation-section-wrapper .reservation-description-container button{
        font-size: 16px;
    }
}
