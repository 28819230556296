.image-preview-wrapper{
    width: 100%;
    height: 100vh;
    background-color: #ffffffc5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close-btn{
    position: absolute;
    top: 100px;
    right: 50px;
    width: 50px;
    height: 50px;
    background-color: #FFF;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.image-preview{
    width: 60%;
    height: 70vh;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media screen and (max-width:660px) {
    .close-btn{
        position: absolute;
        top: 80px;
        right: 20px;
        width: 50px;
        height: 50px;
        background-color: #FFF;
        font-size: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
    }
    .image-preview{
        width: 80%;
        height: 60vh;
        border-radius: 10px;
    }
}