.offer-select-section{
    width: 90%;
    max-width: 1400px;
    border-radius: 10px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.offer-select-button{
    padding: 15px 30px;
    border-radius: 10px;
    border: 0px;
    box-sizing: border-box;
    width: 25%;
    font-size: 18px;
    cursor: pointer;
    background-color: #FFF;
    color: #333;
}
.active{
    background-color: #E5DCBC;
}
.offer-table-container{
    width: 90%;
    max-width: 1400px;
    margin: 30px auto;
    border-radius: 10px;
    overflow: hidden;
    font-family: 'Montserrat';
}
.offer-tr{
    width: 100%;
    background-color: #ffffffa1;
}
.offer-tr:nth-child(even){
    background-color: #eeeeee46;
}
.offer-name-container, .offer-price-container{
    padding: 20px;
}
.offer-price-container{
    width: 12%;
    min-width: 100px;
    text-align: center;
}
.offer-name-container-header, .offer-price-container-header, .offer-type-container-header{
    padding: 20px;
    text-align: center;
    background-color: #E5DCBC;
}
.offer-name-container-header{
    text-align: left;
}
.offer-type-container{
    width: 60px;
    text-align: center;
    font-size: 24px;
    color: #444;
}
@media screen and (max-width: 930px) {
    .offer-select-section{
        flex-direction: column;
        justify-content: flex-start;
    }
    .offer-select-button{
        width: 100%;
    }
    .active{
        background-color: #E5DCBC;
    }
}
@media screen and (max-width: 660px) {
    .offer-table-container td{
        font-size: 14px;
    }
    .offer-table-container{
        width: 100%;
    }
}