.image-gallery-wrapper{
    margin-top: 50px;
    width: 100%;
    background-color: #FFF;
    height: min-content;
    padding: 20px;
    box-sizing: border-box;
}
.image-gallery-header{
    padding: 10px 0;
    width: 100%;
    max-width: 1400px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.image-gallery-header h5{
    font-family: 'Playfair Display';
    font-size: 40px;
    font-weight: 400;
}
.image-gallery-header a{
    font-size: 20px;
    color: #333;
    padding: 5px 15px;
    border-radius: 3px;
    font-family: 'Montserrat';
    display: flex;
    align-items: center;
    gap: 10px;
}
.image-gallery-inner{
    display: grid;
    width: 100%;
    max-width: 1400px;
    grid-template-columns: repeat(4,1fr);
    margin: 10px auto;
    gap: 20px;
    box-sizing: border-box;
    padding: 10px 0;
}

@media screen and (max-width: 1300px) {
    .image-gallery-header h5{
        font-size: 32px;
    }
}
@media screen and (max-width: 960px) {
    .image-gallery-inner{
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width: 630px) {
    .image-gallery-inner{
        grid-template-columns: repeat(2,1fr);
    }
    .image-gallery-header{
        flex-direction: column;
    }
    .image-gallery-header h5{
        font-family: 'Playfair Display';
        font-size: 22px;
        font-weight: 400;
    }
    .image-gallery-header a{
        font-size: 18px;
        margin-top: 10px;
    }
}