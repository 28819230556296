.footer-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #141414;
    margin-top: 100px;
}
.footer-container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    box-sizing: border-box;
    min-height: 200px;
    padding: 70px 20px 120px;
    font-family: 'Montserrat';
    justify-items: flex-start;
    max-width: 1600px;
}
.footer-content{
    width: 70%;
    margin: 0 auto;
}
.footer-content h4{
    font-size: 26px;
    color: #FFF;
    padding: 20px 0;
}
.footer-content ul{
    list-style: none;
}
.footer-content ul li{
    margin-top: 10px;
    color: #ececec;
}
.footer-content ul li a{
    color: #ececec;
}
.footer-content ul li a:hover{
    text-decoration: underline;
}

@media screen and (max-width: 1400px) {
    .footer-content h4{
        font-size: 22px;
    }
    .footer-content ul li{
        font-size: 14px;
    }
}
@media screen and (max-width: 1200px) {
    .footer-container{
        grid-template-columns: repeat(2,1fr);
    }
    .footer-content{
        width: 70%;
        margin: 20px auto;
    }
}
@media screen and (max-width: 630px) {
    .footer-container{
        grid-template-columns: repeat(1,1fr);
    }
}