.header {
    background-color: #FBFAF3;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 20px;
    align-items: center;
    position: sticky;
    top:0;
    z-index: 10;
}
.header .logo-container{
    cursor: pointer;
    font-family: 'Prata', serif;
    letter-spacing: -2px;
    color: #222;
    font-size: 41px;
    font-weight: 400;
}
.menu-button{
    width: 50px;
    height: 50px;
    font-size: 26px;
    color: #333;
    background: none;
    border: 0;
    cursor: pointer;
}
@media screen and (max-width: 960px) {
    .header .logo-container{
        font-size: 28px;
    }
}