.gallery-section-wrapper{
    width: 90%;
    max-width: 1400px;
    margin: auto;
    min-height: 600px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    margin-bottom: 200px;
}
@media screen and (max-width:960px) {
    .gallery-section-wrapper{
        grid-template-columns: repeat(2,1fr);
    }   
}