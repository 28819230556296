.loreal-banner-wrapper{
    width: 90%;
    max-width: 1400px;
    height: 120px;
    display: flex;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin: 50px auto;
    background-color: #ffffff98;
    border-radius: 5px;
    overflow: hidden;
}
.loreal-banner-inner{
    display: flex;
    width: 100%;
}
.loreal-banner-inner img{
    height: 100%;
    width: auto;
    background-color: #FFF;
}
.loreal-banner-description{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    box-sizing: border-box;
    font-family: 'Montserrat';
}
.loreal-banner-description h6{
    font-size: 32px;
    padding-bottom: 5px;
}
.loreal-banner-link-container{
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
}
.loreal-banner-link-container a{
    padding: 10px 15px;
    font-family: 'Montserrat';
    color: #222;
    width: max-content;
    white-space: nowrap;
    font-weight: 600;
    letter-spacing: 0.5px;
    background-color: #FFF;
    display: flex;
    gap: 10px;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media screen and (max-width: 1300px) {
    .loreal-banner-description{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 40px;
        box-sizing: border-box;
        font-family: 'Montserrat';
    }
    .loreal-banner-description h6{
        font-size: 26px;
    }
}
@media screen and (max-width: 630px) {
    .loreal-banner-wrapper{
        width: 90%;
        max-width: 1400px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .loreal-banner-inner{
        display: flex;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
    }
    .loreal-banner-inner img{
        height: 70px;
        width: auto;
        background-color: #FFF;
    }
    .loreal-banner-description{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px 20px;
        box-sizing: border-box;
        font-family: 'Montserrat';
        text-align: center;
    }
    .loreal-banner-description h6{
        font-size: 22px;
        padding-bottom: 5px;
        text-align: center;
    }
    .loreal-banner-link-container{
        width: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        box-sizing: border-box;
    }
    .loreal-banner-link-container a{
        padding: 10px 15px;
        font-family: 'Montserrat';
        color: #222;
        width: max-content;
        white-space: nowrap;
        font-weight: 600;
        letter-spacing: 0.5px;
        background-color: #FFF;
        display: flex;
        gap: 10px;
        align-items: center;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
}