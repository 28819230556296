@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
*{
    margin: 0;
    padding: 0;
}
html{
    scroll-behavior: smooth;
}
body{
    background-color: #f3f3f3;
}
a{
    text-decoration: none;
}