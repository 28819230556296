.hero-wrapper{
    width: 100%;
    height: 85vh;
    background-color: #222;
    margin: auto;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    -webkit-user-drag: none;
}
.hero-image-slider{
    width: 100%;
    height: 100%;
    position: relative;
}
.hero-info-section{
    position: absolute;
    right: 200px;
    top: 50%;
    width: 400px;
    transform: translateY(-50%);
    pointer-events: all;
    user-select: text;
}
.hero-info-section h2{
    font-size: 48px;
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}
.hero-info-link{
    margin-top: 25px;
    background-color: #111;
    color: #FAFAFA;
    display: block;
    width: min-content;
    white-space: nowrap;
    padding: 12px 30px;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Montserrat';
}
.hero-info-links{
    width: 100%;
    height: 60px;
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}
.hero-link{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    color: #232323;
}
.scroll-down-arrow{
    width: 50px;
    height: 50px;
    position: absolute;
    bottom:0;
    left: 50%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #11111177;
    cursor: pointer;
    pointer-events: all;
    transform: translateX(-50%);
}

@media screen and (max-width: 1300px) {
    .hero-wrapper{
        max-height: 700px;
    }
    .hero-info-section{
        right: 80px;
    }
    .hero-info-section h2{
        font-size: 42px;
    }
}

@media screen and (max-width: 960px) {
    .hero-info-section{
        right: 20px;
    }
    .hero-wrapper{
        max-height: 500px;
    }
}

@media screen and (max-width: 680px) {
    .hero-wrapper{
        width: 100%;
        height: 550px;
        max-height: none;
        min-height: none;
        background-color: #222;
        margin: auto;
        overflow: visible;
        user-select: none;
        pointer-events: none;
        -webkit-user-drag: none;
        position: static;
        overflow: hidden;
    }
    .hero-image-slider{
        width: 100%;
        height: 310px;
        position: static;
        display: block;
    }
    .hero-info-section{
        position: static;
        width: 100%;
        pointer-events: all;
        user-select: text;
        background-color: #FFF;
        display: block;
        height: 240px;
        transform: none;
        top: 0;
        padding: 10px 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .hero-info-section h2{
        font-size: 22px;
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        padding: 10px 20px;
        text-align: center;
    }
    .hero-info-link{
        background-color: #111;
        color: #FAFAFA;
        display: block;
        width: min-content;
        white-space: nowrap;
        padding: 12px 30px;
        border-radius: 5px;
        font-size: 14px;
        font-family: 'Montserrat';
        margin: 10px auto;
    }
    .hero-info-links{
        width: 100%;
        height: 60px;
        display: flex;
        gap: 10px;
        margin: 10px auto;
        justify-content: center;
        align-items: center;
    }
    .hero-link{
        width: 50px;
        height: 50px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        cursor: pointer;
        color: #232323;
    }
    .scroll-down-arrow{
        display: none;
    }
}