.popular-item{
    width: 90%;
    margin: 0 auto;
    height: min-content;
    padding: 20px;
    box-sizing: border-box;
}
.popular-item .popular-item-img{
    width: 100%;
    height: 260px;
    border-radius: 5px;
    object-fit: cover;
}
.popular-item .popular-item-heading{
    color: #FFF;
    text-align: center;
    font-family: 'Prata';
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.5px;
    padding: 10px 5px;
}
.popular-item .popular-item-description{
    font-family: 'Montserrat';
    text-align: center;
    color: #EEE;
}
@media screen and (max-width: 1300px) {
    .popular-item .popular-item-heading{
        font-size: 22px;
    }
    .popular-item .popular-item-description{
        font-size: 14px;
    }
}
@media screen and (max-width: 630px) {
    .popular-item{
        width: 90%;
        margin: 0 auto;
        height: min-content;
        padding: 10px;
        box-sizing: border-box;
    }
    .popular-item .popular-item-img{
        width: 100%;
        height: 220px;
        border-radius: 5px;
        object-fit: cover;
    }
    .popular-item .popular-item-heading{
        font-size: 18px;
    }
    .popular-item .popular-item-description{
        font-size: 14px;
    }
}